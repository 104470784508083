import React from 'react';
import { useStaticQuery, graphql } from "gatsby"

import Flex from './flex';
import InspirationCard from './inspirationCard';

const Inspiration = () => {
  const data = useStaticQuery(graphql`
    {
      allInspirationYaml {
        edges {
          node {
            title
            link
            image {
              childImageSharp {
                fluid(maxWidth: 276) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
    >
      <h2>Inspiration</h2>
      <h3>Things I’ve Been Reading, Liking, and Bookmarking</h3>
      <Flex
        width={1}
        flexWrap="wrap"
        justifyContent={['center', null, 'space-between']}
      >
        {
          data.allInspirationYaml.edges.map(({ node }) => (
            <InspirationCard
              key={node.title}
              {...node}
            />
          ))
        }
      </Flex>
    </Flex>
  );
};

export default Inspiration;
