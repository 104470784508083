import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Flex from "../../components/flex"
import Hr from "../../components/hr";
import Inspiration from "../../components/inspiration";
import Section from "../../components/section";

const AboutMe = () => {
  const {
    banner: {
      childImageSharp: {
        fluid: banner,
      },
    },
  } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "about-me-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="About Me" />
      <Flex
        flexDirection="column"
        alignItems="center"
      >
        <Img fluid={banner} style={{ width: '100%', }} />
        <Flex
          flexDirection="column"
          alignItems="center"
          px={[20, null, 0]}
        >
          <Section
            mt={5}
            flexDirection="column"
            alignItems="center"
          >
            <h1 style={{ textTransform: 'uppercase' }}>Smart is in my name</h1>
            <h2>
              User Advocate • Figma Fan • List-Maker • Web Dev Pupil<br />
              Playlist Curator • Amatuer Baker • Animal Enthusiast
            </h2>
            <Flex
              flexDirection="column"
              my={5}
            >
              <p>Currently, I am working on optimizing the mobile User Experience for haltonpardee.com through iterative design and performance improvements. I am also constantly reading about UX design, web design trends, new tech and honing my craft with side projects.</p>
            </Flex>
          </Section>
          <Section
            w={1}
            flexDirection={['column', null, 'row']}
          >
            <Flex
              width={[null, null, "50%"]}
              flexDirection="column"
              alignItems="flex-start"
              mr={[null, null, 36]}
            >
              <h2>2005</h2>
              <p>
                I bought a copy of Photoshop Elements. Ever since that first boot up, I’ve been hooked on design. Armed with that software, a Deviantart account, a cheap DSLR, and an internet connection, I learned how to digitally manipulate image files. Those self-taught skills led to some of my first jobs as a graphic designer.
              </p>

              <h2>2010</h2>
              <p>
                I enrolled in the BSc of Graphic Design Program at the Art Institute of Pittsburgh Online Division. I learned the principles and methodologies of design while simultaneously working full time as a graphic designer. Though I did not complete the program with a degree, the foundational knowledge I gained was instrumental, as was the real world experience.
              </p>
              <h2>2014</h2>
              <p>
                I was playing with web projects more than ever. I made a few landing pages using Adobe Muse (no longer supported), fascinated by the storytelling possiblities of states and scroll effects. I was on the in-house creative team at RealtyONEGroup, staying late working on the responsive redesign of their corporate website. That was my first experience designing high fidelity mockups for different breakpoints of a responsive web layout.
              </p>
            </Flex>
            <Flex
              width={[null, null, "50%"]}
              flexDirection="column"
              alignItems="flex-start"
            >
            <h2>2016</h2>
            <p>
              I stepped into the role of Senior Designer at Halton Pardee + Partners. I currently work on a small in-house team for the boutique brokerage. The company does a great deal of volume in sales, ranking #1 Sales Team in Los Angeles and #2 in California in Real Trends/Wall Street Journal, yet the team is small which demands we be flexible. When I started, there was nobody dedicated to designing or monitoring any aspect of the website or internal digital tools, let alone the User Experience design of them. I took on that role conducting research, usability testing, and designing new features and tools. I regularly create high fidelity mockups of designs and document interaction flows while collaborating with the web development team (third party) to implement these projects and subsequent iterative improvements.
            </p>

            <h2>2018</h2>
            <p>
              I enrolled in the UX Design Certificate Program at University of California San Diego Extension. I said “Let’s make this official” to User Experience Design.
            </p>
              <h2>2019</h2>
              <p>
              In December 2019, I completed the UX Design program with a certificate. I started implementing skills I learned immediately with the kick off of a full website redesign project at Halton Pardee.
              </p>
          </Flex>
        </Section>
        <Hr />
        <Section>
          <Inspiration />
        </Section >
      </Flex>
    </Flex>
  </Layout>
  );
}

export default AboutMe
