import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image"

import Flex from './flex';

const InspirationCard = ({
  image,
  title,
  link,
}) => {
  const Wrapper = link ? 'a' : React.Fragment;
  const wrapperProps = link ? {
    href: link,
    target: '_blank',
  } : {};
  return (
    <Flex
      flexDirection="column"
      width={276}
      m={2}
    >
      <Wrapper {...wrapperProps}>
        <Img fluid={image.childImageSharp.fluid} style={{ width: '100%' }} />
      </Wrapper>
      <h4><Wrapper {...wrapperProps}>{title}</Wrapper></h4>
    </Flex>
  );
};

InspirationCard.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  link: PropTypes.string,
};

export default InspirationCard;
